import React, {useEffect, useState} from 'react';
import './productRow.scss';
import arrow from '../../../assets/img/Arrow_right_long_light (1).svg';
import arrow1 from '../../../assets/img/Arrow_right_long_light.svg';
import $ from 'jquery';
import ProductItem from "../../common/product-item";
import {useHistory} from "react-router-dom";
import BannerProduct from '../../common/banner-product/BannerProduct';
import moment from 'moment';

function ProductRow(props) {
    const history = useHistory();
    const [productsLimit, setProductsLimit] = useState([])

    function makeTimer(timeEnd) {
        // var endTime = new Date(timeEnd).getTime()
            var endTime = timeEnd;
            endTime = moment(endTime, "YYYY-MM-DD HH:mm:ss").toDate();
            var now = new Date().getTime();
            var distance = endTime - now;
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if (days < "10") { days = "0" + days; }
            if (hours < "10") { hours = "0" + hours; }
            if (minutes < "10") { minutes = "0" + minutes; }
            if (seconds < "10") { seconds = "0" + seconds; }

            if (!Number.isNaN(days) && days) {
              $("#days").html(days + ' ngày&nbsp');
            }
            if (!Number.isNaN(hours) && !Number.isNaN(minutes) && !Number.isNaN(seconds)) {
                $("#hours").html(hours + ':');
                $("#minutes").html(minutes + ':');
                $("#seconds").html(seconds);
            }
    }
    setInterval(function() {makeTimer(props.end_time)}, 1000);

    useEffect(() => {
        let productLimit = props.products && props.products.slice(0, 10)
        setProductsLimit(productLimit)
    }, [props.products])

    function redirectPage() {
        if (props.deal) {
            return history.push("/deal/" + props.slug)
        }
        return history.push("/group/" + props.slug)
    }

    return (
        <div className="container pl-0 pr-0 product-wapper">
            <div className="header-productTitle mt-5">
                <div className="grid mr-auto ">
                    <h2 className="mb-0" style={{cursor:'pointer', color: '#C6910F'}} onClick={redirectPage}>{props.name}</h2>
                    {props.deal === 1 
                    ?
                        <div  id="timer">
                            <p className="mr-2">Kết thúc trong </p>
                            <p id="days"></p>
                            <p id="hours"></p>
                            <p id="minutes"></p>
                            <p id="seconds"></p>
                        </div> 
                    : ''
                    }
                </div>
                {/*<p className="more_product pc" onClick={redirectPage}>Xem thêm <img className="arrow-more" alt="" src={arrow} ></img><img className="arrow-more-1" alt="" src={arrow1} ></img></p>*/}
            </div>
            <div className="mb-3">
              {/* <BannerProduct cover={props.banner} slug={props.slug} deal={props.deal}/> */}
            </div>
            <div className="parent grid grid-5 grid-gap-20 ">
                {productsLimit !== null ? productsLimit.map((product, index) => <ProductItem key={index} product={product} /> ) : ''}
            </div>
            {/*<p className="more_product mobile" onClick={redirectPage}>Xem tất cả</p>*/}
        </div>
    )
}

export default ProductRow
