import React, {useState,useEffect} from 'react';
import LazyLoad from "react-lazyload";
import loveIn from "../../../assets/img/fill 16 - white.svg";
import loveOut from '../../../assets/img/liner - white.svg'
import icon_cart from "../../../assets/img/Group 68.svg";
import {Link, useHistory} from 'react-router-dom';
import {finalPrice, formatCurrency, getDiscount} from "../../../helpers/Function";
import { useDispatch, useSelector } from 'react-redux';
import {GetAllDataCart} from '../../../actions/Cart';
import {getImageMedia} from "../../../helpers/Function";
import Login from '../../../pages/auth/login';
import {CartApiService, ProductFavoriteApiService} from '../../../ultils/EndPoint';
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axios from 'axios';
import ENDPOINT from '../../../ultils/EndPoint';
import { addFavorite, deleteFavorItem } from '../../../actions/FavoriteProduct';
import {websiteActions} from "../../../actions";
import {Button} from "react-bootstrap";

function Index({product}) {
    const TYPE_PRODUCT_ORDER = 3;
    const userToken = localStorage.getItem('User-token');
    const dispatch = useDispatch();
    const [showLogin, setShowLogin] = useState(false);
    const state = useSelector(state => state.todoProduct);
    const [nameForcart, setNameForcart] = useState(false);
    const [showStatus, setShowStatus] = useState(false);
    const [message, setMessage] = useState(false);
    const handleCloseStatus = () => {
      setShowStatus(false)
    };
    const showAlert = (message) => {
      setMessage(message);
      setShowStatus(true);
    }

    const history = useHistory();
    // set token check 
    const tokenUser = localStorage.getItem('User-token')
    // set show for modal
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false)
    };
    var data = [];
    state.cartData.products && state.cartData.products.map(cart => 
      {data.push({
          quantity: cart.quantity, 
          product_id: cart.id,
          coupon_id: cart.coupon_id,
          selected: true,
      })
      }
    )

  function checkQuantityStock(quantity, product) {
    if (product.product_stock < quantity) {
      showAlert('Số lượng sản phẩm không có đủ trong kho!');
      return false
    }
    return true
  }

    const handleAddToCart = (product) => {
      // if(!userToken){
      //   setShowLogin(true)
      //   setNameForcart(true)
      // } else 
      if(product.product_stock == 0) {
        showAlert('Sản phẩm đã hết hàng!');
        return;
      } else if(product.status == 0) {
        showAlert('Sản phẩm đang ngừng bán!');
        return;
      } else {
        var result = data.find(obj => {
          return obj.product_id === product.id
        })
        if(result){
          let isUpdate = true
          data && data.map(check => {
              if(check.product_id === product.id){
                check.quantity += 1
              }
          })
          if(userToken){
            if (isUpdate) {
              CartApiService.postCart(data).then(res => {
                if(res.status === true){
                  setShow(true)
                  dispatch(GetAllDataCart(res.data));
                }
              })
            }
          }else {
            if (isUpdate) {
              CartApiService.postCartNotAuth(data).then(res => {
                if(res.status === true){
                  setShow(true)
                  localStorage.setItem("cart_not_auth", JSON.stringify(res.data.products));
                  dispatch(GetAllDataCart(res.data));
                }
              })
            }
          }
        }else {
          if(userToken){
            CartApiService.postCart([{
                product_id : product.id,
                quantity : 1,
                selected : true,
            }].concat(data)).then(res => {
                if(res.status === true){
                    setShow(true)
                    dispatch(GetAllDataCart(res.data));
                }
            })
          }else {
            CartApiService.postCartNotAuth([{
              product_id : product.id,
              quantity : 1,
              selected : true,
            }].concat(data)).then(res => {
                if(res.status === true){
                    setShow(true)
                    localStorage.setItem("cart_not_auth", JSON.stringify(res.data.products));
                    dispatch(GetAllDataCart(res.data));
                }
            })
          }
        }
      }
    }

    const [active, setActive] = useState(false)

    const handleGoToCart = async () => {
        await dispatch(websiteActions.setFooter(false))
        await history.replace('/cart')
    }
 
    // post data favorite love
    const handleLove = (e) => {
      if(!userToken){
        setShowLogin(true)
        setNameForcart(true)
      }else{
        if(active === false){
          ProductFavoriteApiService.postFavorite({product_id: e}).then(res => 
            {if(res.status === true){
              setActive(true)
              dispatch(addFavorite(e))
             }}
          )
        }else {
          const data ={
            product_id : e
          }
          axios.delete(`${ENDPOINT.FAVORITE_PRODUCT}/delete`, {
            headers: {
                Authorization: 'Bearer ' + tokenUser
            }, data: data
            })
            .then(function (response) {
                if(response.status === 200) {
                    setActive(false)
                    dispatch(deleteFavorItem(e))
                }
            })
            .catch(err =>
                console.log(err)
            )
        }
      }
      
    }
  
    const getAllDataFavor = useSelector(state => state.getFavoriteProduct.listDataGet)

    useEffect(() => {
      getAllDataFavor && getAllDataFavor.map(checkedFavor => {
        if(checkedFavor.id === product.id){
          setActive(true)
        }
      })
    }, [getAllDataFavor])

    return (
      <div className="main_item_hover">
          <Login name={ nameForcart === true ? 'Bạn cần đăng nhập để thực hiện chức năng này' : ''} show={showLogin} close={() => setShowLogin(false)} onHide={() => setShowLogin(false)}/>
          <div className="child">
              <LazyLoad height={200}>
                  <div className="discount-wapper">
                    {product.medias !== null ? <Link to={"/san-pham/" + product.slug}><img src={getImageMedia(product.medias)} alt={product.name}/></Link> : null}
                      {((product.discount > 0 && product.discount < product.price) || (product.deal && product.deal.id)) ?
                        <p className="discount">{getDiscount(product)}%</p>
                        : ''  }
                      <div onClick={()=> handleLove(product.id)} className="loveCount mr-4 mt-2">
                      {
                        active === true ?
                          <img src={loveIn} style={{'width': '18px', 'height': '18px'}} alt="love-icon"></img>
                        :   <img src={loveOut} style={{'width': '18px', 'height': '18px'}} alt="love-icon"></img>
                      }
                      </div>
                      
                  </div>
              </LazyLoad>
              <div>
                <Link to={"/san-pham/" + product.slug}>
                  <p className="ml-3">{product.name}</p>
                </Link>
                  <div className="price-product">
                      { product.type_id === TYPE_PRODUCT_ORDER ?
                        <div className="d-flex justify-content-center align-items-baseline">
                          <h2>Liên hệ</h2>
                        </div>
                        : ((product.discount > 0 && product.discount < product.price) || product.deal.id ?
                        <div className="d-flex justify-content-center align-items-baseline ml-3">
                            <h2>{formatCurrency(finalPrice(product))}đ</h2>
                            <h4 className="ml-2">{formatCurrency(product.price)}đ</h4>
                        </div>
                        :
                        <div className="d-flex justify-content-center align-items-baseline ml-3">
                            <h2>{formatCurrency(product.discount)}đ</h2>
                        </div>)
                      }
                    {product.type_id !== TYPE_PRODUCT_ORDER ?
                      <div onClick={() => handleAddToCart(product)} className="cart-hover mr-3 mb-2">
                        <img alt="" src={icon_cart} style={{'width': '16px', 'height': '16px'}}></img>
                      </div> : ''}
                </div>
            </div>
        </div>

        <Modal show={show} onHide={handleClose} className="modal_deleteAdressUser modal_AddSuccess_Cart">
              <Modal.Header closeButton className="grid">
                  <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body className="mt-3">
                  <Form>
                      <p className="mb-3">Thêm vào giỏ hàng thành công</p>       
                      <div variant="primary" onClick={async () => {
                          await dispatch(websiteActions.setFooter(false))
                          handleGoToCart()
                          handleClose()
                      }} className="form-group mb-0">
                          <button type="button" className="btn btn-submit goto_cart">Xem giỏ hàng</button>
                      </div>
                  </Form>
              </Modal.Body>
          </Modal>
        <Modal className="modal_deleteAdressUser" show={showStatus} onHide={handleCloseStatus}>
          <Modal.Header closeButton>

          </Modal.Header>
          <Modal.Body>
            <p>{message}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseStatus}>
              Đóng
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
}
export default Index;
